.distMap {
  position: relative;
  width: 100vw;
  height: 110vw;
}
.distMap .legend {
  height: 10vw;
  overflow: hidden;
}
.distMap .legend .label,
.distMap .legend .block {
  justify-content: center;
  display: flex;
  clear: both;
  overflow: hidden overlay;
}
.distMap .legend .block span {
  display: block;
  box-sizing: border-box;
  height: 5vw;
  border: 1px solid #999;
  width: 6vw;
}

.distMap .legend .label span {
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  width: 6vw;
  height: 5vw;
  line-height: 5vw;
  font-size: 3vw;
}
.distMap .leaflet-label-pane {
  z-index: 649;
}
.distMap .leaflet-tooltip.label {
  width: 10vw;
  height: 3vw;
  line-height: 3vw;
  padding: 0;
  font-size: 2vw;
  color: #fff;
  font-family: 'Barlow Condensed', sans-serif;
  opacity: 1;
  text-align: center;
  text-shadow: 0 0 0.3em #000, 0 0 0.4em #000, 0 0 0.5em #000;
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}
.distMap .map {
  width: 100vw;
  height: 100vw;
  background-color: #ffffff;
  transition: opacity 500ms;
}

.showNumberSwitch {
  z-index: 1100;
  height: 6vw;
  width: 30vw;
  font-size: 3vw;
  position: absolute;
  bottom: 2vw;
  left: 2vw;
  line-height: 6vw;
  border-radius: 3vw;
  background: #eee;
}
.showNumberSwitch .btn {
  border-radius: 3vw;
  transition: color 300ms, background 300ms;
  height: 6vw;
  width: 15vw;
  float: left;
  text-align: center;
}
.showNumberSwitch .btn.active {
  background: #666;
  color: #fff;
}
