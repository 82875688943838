.DistRank {
  height: 110vw;
  animation: fade-in 300ms;
}
.DistRank .cell {
  display: inline-block;
  font-size: 4vw;
  width: 25vw;
  box-sizing: border-box;
  padding: 0 3vw;
}
.DistRank .tbody {
  height: 100vw;
  overflow-y: scroll;
}
.DistRank .thead {
  font-weight: bold;
}
.DistRank .row.selected {
  font-weight: bold;
}
.DistRank .cell {
  position: relative;
  text-align: right;
  z-index: 2;
}
.DistRank .cell:first-child {
  text-align: left;
}

.DistRank .cell:nth-child(1) {
  color: #333333;
}
.DistRank .cell:nth-child(2) {
  color: #0069cc;
}
.DistRank .cell:nth-child(3) {
  color: #28a44d;
}
.DistRank .cell:nth-child(4) {
  color: #5a1daf;
}

.DistRank .row {
  position: relative;
  box-sizing: border-box;
  width: 100vw;
  height: 8vw;
  line-height: 8vw;
}
.DistRank .tbody .row {
  color: #333;
  background-color: #e5f3ff;
}
.DistRank .tbody .row.row.selected {
  background-color: #b3dbff;
}
.DistRank .bar {
  position: absolute;
  top: 0vw;
  bottom: 0vw;
  z-index: 1;
  transition: width 300ms;
}
.DistRank .bar-left {
  background-color: #eafaef;
  left: 0;
}
.DistRank .bar-right {
  background-color: #f0e9fc;
  right: 0;
}
.DistRank .selected .bar-left {
  background-color: #c1f0d0;
}
.DistRank .selected .bar-right {
  background-color: #d1bcf6;
}
