.loading {
  text-align: center;
  font-size: 6vw;
  line-height: 50vh;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.App {
  animation: fade-in 300ms;
}
.mapTitle {
  padding: 2vw;
  text-align: center;
  font-size: 6vw;
  background-color: #0084ff;
  color: #fff;
}
.hideChart {
  display: none;
}
.viewTypeTab {
  margin: 0 2vw;
  padding: 4px;
  background-color: #eee;
  border-radius: 10px;
  display: flex;
  clear: both;
}
.viewTypeTab span {
  flex: 1 1 0;
  display: block;
  height: 8vw;
  font-size: 5vw;
  line-height: 8vw;
  text-align: center;
}
.viewTypeTab span.active {
  font-weight: bold;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 3px #999;
}

.datasource,
.updatetime {
  height: 4vw;
  line-height: 4vw;
  font-size: 3vw;
  text-align: right;
  color: #999;
  padding: 0 2vw;
}
.copyright {
  font-size: 4vw;
  line-height: 10vw;
  text-align: center;
  color: #aaa;
}
.adName {
  font-size: 6vw;
  line-height: 8vw;
  font-weight: bold;
  padding: 0 2vw;
}
.adName .action {
  float: right;
  font-size: 4vw;
  line-height: 8vw;
  font-weight: unset;
  color: #0069cc;
}
.adName .action span {
  margin-left: 2vw;
}
.playControl {
  height: 8vw;
  margin: 0 2vw;
  text-align: right;
}
.playControl .btn {
  animation: fade-in 300ms;
  background-color: #666;
  color: #eee;
  padding: 0 2vw;
  border-radius: 4vw;
  font-size: 4vw;
  line-height: 8vw;
  height: 8vw;
  position: relative;
  z-index: 1;
}
.playControl .btn-right {
  float: right;
}
.playControl .btn-left {
  float: left;
}
.playControl .btnPlay {
  width: 60vw;
}
.playControl .btnPlay .playItem {
  float: left;
  text-align: center;
  height: 8vw;
  line-height: 8vw;
}
.react-icons {
  display: block;
  margin: 1vw 0;
  width: 6vw;
  height: 6vw;
}
.playControl .btnPlay .playItem.icon {
  width: 6vw;
}
.playControl .btnPlay .playItem.date {
  width: 24vw;
}
@keyframes playDate-fadein {
  0% {
    opacity: 0;
    width: 0;
  }
}
.labelSwitch {
  position: absolute;
}
.panel {
  background-color: #fff;
  padding: 2vw 0;
  margin-bottom: 4vw;
  position: relative;
}
.chartPannel {
  margin-bottom: 0;
}
.todayData {
  display: flex;
  clear: both;
}
.todayData > div {
  flex: 1 1 10vw;
  color: white;
  margin: 2vw;
  padding: 2vw;
  text-align: center;
  border-radius: 2vw;
}
.todayData .name {
  font-size: 5vw;
  line-height: 6vw;
  height: 6vw;
}
.todayData .sum {
  font-weight: bold;
  font-size: 6vw;
  line-height: 8vw;
  height: 8vw;
}
.todayData .add {
  font-size: 3vw;
  height: 4vw;
  line-height: 4vw;
  position: relative;
  animation: todayAdd-fadein 1000ms;
}
@keyframes todayAdd-fadein {
  0% {
    top: 2vw;
    opacity: 0;
  }
  10% {
    top: 2vw;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.trendChart {
  height: 80vw;
}
